<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuCartBlock
			:borderBottom="true"
		></LuCartBlock>

	2019-06-08	init

-->

<template>
	<div class="LuCartBlock contentModule" :class="elmClasses">
		<div class="LuCartBlock__inner border border--top border--strong">
			<div class="LuCartBlock__header vSpace vSpace--LuCartRow" v-if="number">
				<div class="flex flex--top ">
					<span class="bigNumber">{{number}}</span>
					<strong v-html="title"></strong>
				</div>
			</div>
			<div class="LuCartBlock__body" :class="{'border border--strong border--bottom' : borderBottom}">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuCartBlock',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			number: [Number, Boolean],
			title: [String, Boolean],
			borderBottom : {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {
			addChildClasses(){
				if( this.$slots.default ){
					// add row classes to each slot child
					this.$slots.default.forEach((vnode, index) => {
						if( vnode.elm && vnode.elm.classList ) {
							vnode.elm.classList.add('LuCartBlock__row')
							vnode.elm.classList.add('border')
							vnode.elm.classList.add('border--top')

							if( index == this.$slots.default.length-1 ){
								vnode.elm.classList.add('border--bottom')
							}
						}
					});
				}
			},
		},
		updated: function () {
			//console.log('updated:', this.$slots.default)
			//this.addChildClasses()
		},
		created() {},
		mounted() {
			//console.log('mounted:', this.$slots.default)
			//this.addChildClasses()
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	.LuCartBlock {
		position: relative;
	}
	.LuCartBlock + .LuCartBlock {
		margin-top: -3px;
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
