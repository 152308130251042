<template>
	<div class="LubokCartView view flex flex--column" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="grid grid--pageTemplateLinea vSpace vSpace--page hSpace hSpace--page maxWidth maxWidth--page">

			<!-- Warenkorb -->
			<template v-if="!$route.params.slug">
				<LuPageHeader
					:title="$t('title.cart')"
				></LuPageHeader>

				<!-- products -->
				<LuCartBlock v-if="!$store.getters.cartIsEmpty">
					<!-- header -->
					<LuCartRow name="products" v-if="!$store.getters.cartIsEmpty">
						<!-- index -->
						<div v-if="!$root.isSmallDevice">
							<br />
							<br />
						</div>
						<!-- product -->
						<div>
							<div class="font font--sizeMini" v-html="$t('title.product')"></div>
						</div>
						<!-- priceInclTaxes -->
						<div>
							<div class="font font--sizeMini text text--right" v-html="$t('title.priceInclTaxes')"></div>
						</div>
						<!-- number -->
						<div>
							<div class="font font--sizeMini text text--center" v-html="$t('title.number')"></div>
						</div>
						<!-- sumInklTaxes -->
						<div>
							<div class="font font--sizeMini text text--right" v-html="$t('title.sumInklTaxes')"></div>
						</div>
						<!-- remove -->
						<div>
							<br />
						</div>
					</LuCartRow>
					<!-- products -->
					<LuCartRow name="products" v-for="(post, index) in $store.getters.itemsInCart" :key="index"
							   class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
						<!-- index -->
						<div v-if="!$root.isSmallDevice">
							{{index+1}}.
						</div>
						<!-- product -->
						<div>
							<router-link class="block" :to="$root.getPathFromUrl(post.link)">
								<span v-html="$root.getTranslatedAcfValue(post.acf, 'author')"></span><br />
								<strong v-html="$root.getTranslatedAcfValue(post.acf, 'postTitle')"></strong>
							</router-link>
						</div>
						<!-- priceInclTaxes -->
						<div>
							<LuPrice
								class="block text text--right"
								:price="post.acf.priceEur"
							></LuPrice>
						</div>
						<!-- number -->
						<div>
							<template v-if="!$root.isSmallDevice">
								<BaseInput
									class="font font--sizeDefault"
									type="number" min="1" step="1"
									@change="$store.commit('changeNumberOf', { id: post.id, number: $event.target.value})"
									:value="post.count"
								></BaseInput>
							</template>
							<template v-else>
								<div class="text text--center">
									{{post.count}}
								</div>
							</template>
						</div>
						<!-- sumInklTaxes -->
						<div>
							<LuPrice
								class="block text text--right"
								:price="post.count * post.acf.priceEur"
							></LuPrice>
						</div>
						<!-- remove -->
						<div>
							<div class="text text--right">
								<BaseButton
									class="BaseButton--removeFromCart"
									label="×"
									@click.native="$store.commit('removeFromCart', post.id)"
								></BaseButton>
							</div>
						</div>
					</LuCartRow>
				</LuCartBlock>

				<!-- productsSum -->
				<LuCartBlock v-if="!$store.getters.cartIsEmpty">
					<LuCartRow name="productsSum" v-if="!$store.getters.cartIsEmpty"
							   class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
						<!-- index -->
						<div v-if="!$root.isSmallDevice"></div>
						<!-- price label -->
						<div>
							<div class="text text--right">
								<span class="font font--sizeSmall" v-html="$t('title.includedTaxesSum')"></span><br />
								<span class="font font--sizeSmall" v-html="$t('title.totalExclTaxes')"></span><br />
								<span class="font font--sizeSmall font--bold" v-html="$t('title.totalInclTaxes')"></span><br />
							</div>
						</div>
						<!-- spacer -->
						<div v-if="!$root.isSmallDevice"></div>
						<!-- prices -->
						<div>
							<div class="font XXfont--sizeSmall text text--right">
								<LuPrice :price="$store.getters.sumOfAllProductsTaxes"></LuPrice><br />
								<LuPrice :price="$store.getters.nettoSumOfAllProducts"></LuPrice><br />
								<LuPrice class="font font--bold" :price="$store.getters.bruttoSumOfAllProducts"></LuPrice><br />
							</div>
						</div>
						<div></div>
					</LuCartRow>
				</LuCartBlock>

				<!-- no items in cart  -->
				<LuCartBlock v-if="$store.getters.cartIsEmpty">
					<LuCartRow name="products" v-if="$store.getters.cartIsEmpty">
						<div style="grid-column: span 6"
							 class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
							<br />
							<span v-html="$t('message.noItemsInCart')"></span><br />
							<br />
						</div>
					</LuCartRow>
				</LuCartBlock>

				<!-- products: submit button -->
				<div class="text text--right">
					<LuSpacer :lines="2"></LuSpacer>

					<!-- button: backToProduct -->
					<template v-if="app.routeFrom.name=='ProductView'">
						<BaseButton
							class="BaseButton--cartNav"
							tag="a"
							:label="$t('button.back')"
							@click.native="$router.go(-1)"
						></BaseButton>&nbsp;
					</template>

					<!-- button: goToShop -->
					<template v-else>
						<BaseButton
							class="BaseButton--cartNav"
							tag="a"
							:label="$t('button.goToShop')"
							@click.native="$router.push({ name : 'ShopView' })"
						></BaseButton>&nbsp;
					</template>

					<!-- button: proceedToCheckout -->
					<BaseButton
						class="BaseButton--cartNav BaseButton--cta"
						:class="{'BaseButton--disabled' : !this.$store.getters.stepIsValid('cart')}"
						tag="a"
						:label="$t('button.proceedToCheckout')"
						@click.native="$router.push({ name : 'CheckoutView', params : { slug : 'shipping' } })"
					></BaseButton>

					<LuSpacer :lines="6"></LuSpacer>
				</div>
			</template>

			<!-- Checkout 1/4 - Versand -->
			<template v-else-if="$route.params.slug == 'shipping'">
				<LuPageHeader
					:title="'Checkout 1/4'"
					:subTitle="$t('title.shipping')"
				></LuPageHeader>

				<!-- shipping -->
				<LuCartBlock>
					<!-- shipping: germany -->
					<LuCartRow name="shipping">
						<!-- radio -->
						<div class="flex flex--center">
							<BaseInput
								class=""
								type="radio"
								name="shippingRegion"
								@change="$store.commit('changeShippingRegion', 'germany')"
								:checked="$store.getters.shippingRegion === 'germany'"
							></BaseInput>
						</div>
						<!-- text -->
						<div style="grid-column: span 2">
							<strong class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }" v-html="$t('title.shippingToGermany')"></strong><br />
							<div class="vSpace vSpace--navRow">
								<span v-html="$t('message.forFree')"></span>
							</div>
						</div>
						<!-- price -->
						<div>
							<template v-if="$store.getters.shippingRegion === 'germany'">
								<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
									<LuPrice
										class="block text text--right"
										:price="$store.getters.shippingRegionPrice({ name : 'germany' })"
									></LuPrice>
								</div>
							</template>
							<template v-else>
								<div class="text text--right font font--mono">———&nbsp;</div>
							</template>
						</div>
					</LuCartRow>
					<!-- shipping: eu -->
					<LuCartRow name="shipping">
						<!-- radio -->
						<div class="flex flex--center">
							<BaseInput
								class=""
								type="radio"
								name="shippingRegion"
								@change="$store.commit('changeShippingRegion', 'eu')"
								:checked="$store.getters.shippingRegion === 'eu'"
							></BaseInput>
						</div>
						<!-- text -->
						<div style="grid-column: span 2">
							<strong class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }"  v-html="$t('title.shippingToEU')"></strong><br />

							<div class="vSpace vSpace--navRow">
								<span v-html="$t('message.flatRate')"></span>&nbsp;<LuPrice
									class="inlineBlock"
									:noFontClasses="true"
									:price="$store.getters.shippingRegionPrice({ name : 'eu' })"
								></LuPrice>&nbsp;<span v-html="$t('message.flatRateInklVat')"></span>
							</div>
						</div>
						<!-- price -->
						<div>
							<template v-if="$store.getters.shippingRegion === 'eu'">
								<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
									<LuPrice
										class="block text text--right"
										:price="$store.getters.shippingRegionPrice({ name : 'eu' })"
									></LuPrice>
								</div>
							</template>
							<template v-else>
								<div class="text text--right font font--mono">———&nbsp;</div>
							</template>
						</div>
					</LuCartRow>
					<!-- shipping: world -->
					<LuCartRow name="shipping">
						<!-- radio -->
						<div class="flex flex--center">
							<BaseInput
								class=""
								type="radio"
								name="shippingRegion"
								@change="$store.commit('changeShippingRegion', 'world')"
								:checked="$store.getters.shippingRegion === 'world'"
							></BaseInput>
						</div>
						<!-- text -->
						<div v-if="!$root.isSmallDevice">
							<strong class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }" v-html="$t('title.shippingToWorldwide')"></strong>
							<div class="vSpace vSpace--navRow">
								<span v-html="$t('message.shippingToWorldwideInfo')"></span>
							</div>
							<div class="vSpace vSpace--navRow">
								<span v-html="$t('message.shippingToWorldwideInfo2')"></span>
							</div>
						</div>
						<!-- select country dropdown -->
						<div v-if="!$root.isSmallDevice">
							<BaseSelect
								class="block width width--100"
								label=""
								:value="$store.getters.shippingCountry"
								@change="$store.commit('changeShippingCountry', $event.target.value)"
								:defaultOption="$t('message.chooseCountry')"
								:options="$store.getters.countryNames({ region : 'world' })"
							></BaseSelect>
						</div>
						<div v-if="$root.isSmallDevice" style="grid-column: span 2">
							<strong class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }" v-html="$t('title.shippingToWorldwide')"></strong>
							<div class="vSpace vSpace--navRow">
								<BaseSelect
									class="block width width--100"
									label=""
									:value="$store.getters.shippingCountry"
									@change="$store.commit('changeShippingCountry', $event.target.value)"
									:defaultOption="$t('message.chooseCountry')"
									:options="$store.getters.countryNames({ region : 'world' })"
								></BaseSelect>
							</div>
							<div class="vSpace vSpace--navRow">
								<span v-html="$t('message.shippingToWorldwideInfo')"></span>
							</div>
							<div class="vSpace vSpace--navRow">
								<span v-html="$t('message.shippingToWorldwideInfo2')"></span>
							</div>
						</div>
						<!-- price -->
						<div>
							<template v-if="$store.getters.shippingRegion === 'world'">
								<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
									<LuPrice
										class="block text text--right"
										:price="$store.getters.shippingCountryPrice({ name : $store.getters.shippingCountry })"
									></LuPrice>
								</div>
							</template>
							<template v-else>
								<div class="text text--right font font--mono">———&nbsp;</div>
							</template>
						</div>
					</LuCartRow>
				</LuCartBlock>

				<!-- shipping: submit button -->
				<div class="text text--right">
					<LuSpacer :lines="2"></LuSpacer>

					<BaseButton
						class="BaseButton--cartNav"
						tag="a"
						:label="$t('button.backToCart')"
						@click.native="$router.push({ name : 'CartView' })"
					></BaseButton>

					&nbsp;

					<BaseButton
						class="BaseButton--cartNav BaseButton--cta"
						:class="{'BaseButton--disabled' : !this.$store.getters.stepIsValid('shipping')}"
						tag="a"
						:label="$t('button.continueCheckout')"
						@click.native="$router.push({ name : 'CheckoutView', params : { slug : 'payment' } })"
					></BaseButton>

					<LuSpacer :lines="6"></LuSpacer>
				</div>
			</template>

			<!-- Checkout 2/4 - Bezahlmethode -->
			<template v-else-if="$route.params.slug == 'payment'">
				<LuPageHeader
					:title="'Checkout 2/4'"
					:subTitle="$t('title.payment')"
				></LuPageHeader>

				<!-- payment -->
				<LuCartBlock>
					<!-- payment: invoice -->
					<LuCartRow name="payment">
						<!-- radio -->
						<div class="flex flex--center">
							<BaseInput
								class=""
								type="radio"
								name="paymentOption"
								@change="$store.commit('changeShippingRegion', 'germany')"
								:checked="$store.getters.paymentOption === 'Invoice'"
							></BaseInput>
						</div>
						<!-- text -->
						<div>
							<strong class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }" v-html="$t('title.paymentWithInvoice')"></strong>
							<div class="font font--sizeDefault vSpace vSpace--navRow" v-html="$t('message.paymentWithInvoiceInfo')"></div>
						</div>
						<!-- spacer -->
						<div v-if="!$root.isSmallDevice"></div>
					</LuCartRow>
				</LuCartBlock>

				<!-- payment: submit button -->
				<div class="text text--right">
					<LuSpacer :lines="2"></LuSpacer>

					<BaseButton
						class="BaseButton--cartNav"
						tag="a"
						:label="$t('button.back')"
						@click.native="$router.push({ name : 'CheckoutView', params : { slug : 'shipping' } })"
					></BaseButton>

					&nbsp;

					<BaseButton
						class="BaseButton--cartNav BaseButton--cta"
						:class="{'BaseButton--disabled' : !this.$store.getters.stepIsValid('payment')}"
						tag="a"
						:label="$t('button.continueCheckout')"
						@click.native="$router.push({ name : 'CheckoutView', params : { slug : 'customer' } })"
					></BaseButton>

					<LuSpacer :lines="6"></LuSpacer>
				</div>
			</template>

			<!-- Checkout 3/4 - Rechnungs + Lieferadresse -->
			<template v-else-if="$route.params.slug == 'customer'">
				<LuPageHeader
					:title="'Checkout 3/4'"
					:subTitle="$t('title.billingAndDeliveryAddresses')"
				></LuPageHeader>

				<!-- customer: addresses-->
				<LuCartBlock>
					<!-- Rechnungsadresse -->
					<!-- Unternehmen -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.company')}}</div>
						<div class="font font--sizeMedium">
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.company"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'company', value: $event.target.value})"
							></BaseInput>
						</div>
					</LuCartRow>
					<!-- name -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.name')}}<span class="requiredIndicator"></span></div>
						<div class="font font--sizeMedium">
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.name"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'name', value: $event.target.value})"
							></BaseInput>
						</div>
					</LuCartRow>
					<!-- street -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.street')}}<span class="requiredIndicator"></span></div>
						<div class="font font--sizeMedium">
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.street"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'street', value: $event.target.value})"
							></BaseInput>
						</div>
					</LuCartRow>
					<!-- name -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.zipCode')}}<span class="requiredIndicator"></span></div>
						<div class="font font--sizeMedium">
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.zipCode"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'zipCode', value: $event.target.value})"
							></BaseInput>
						</div>
					</LuCartRow>
					<!-- city -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.city')}}<span class="requiredIndicator"></span></div>
						<div class="font font--sizeMedium">
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.city"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'city', value: $event.target.value})"
							></BaseInput>
						</div>
					</LuCartRow>
					<!-- country -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.country')}}<span class="requiredIndicator"></span></div>
						<div class="font font--sizeMedium">
							<!--
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.country"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'country', value: $event.target.value})"
							></BaseInput>
							-->
							<BaseSelect
								class="block width width--100"
								:value="$store.getters.billingAddress.country"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'country', value: $event.target.value})"
								:defaultOption="$t('message.chooseCountry')"
								:options="$store.getters.countryNames()"
							></BaseSelect>
						</div>
						<!--
						<pre>{{$store.getters.countryNames()}}</pre>
						-->
					</LuCartRow>
					<!-- mail -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.mail')}}<span class="requiredIndicator"></span></div>
						<div class="font font--sizeMedium">
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.mail"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'mail', value: $event.target.value})"
							></BaseInput>
						</div>
					</LuCartRow>
					<!-- phone -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.phone')}}</div>
						<div class="font font--sizeMedium">
							<BaseInput
								type="text"
								:value="$store.getters.billingAddress.phone"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'phone', value: $event.target.value})"
							></BaseInput>
						</div>
					</LuCartRow>
					<!-- message -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>{{$t('title.message')}}</div>
						<div class="font font--sizeMedium">
							<BaseTextarea
								:value="$store.getters.billingAddress.message"
								@change="$store.commit('changeAddress', { type: 'billingAddress', key: 'message', value: $event.target.value})"
							></BaseTextarea>
						</div>
					</LuCartRow>
					<!-- Checkbox: abweichende Lieferadresse -->
					<LuCartRow name="billingAndDeliveryAddresses">
						<div>
							<br />
							<br />
							<br />
						</div>
						<div class="flex flex--middle">
							<BaseInput
								type="checkbox"
								@change="$store.commit('changeDeliveryAddressIsDifferent', $event.target.checked)"
								:checked="$store.getters.deliveryAddressIsDifferent"
							></BaseInput> &nbsp;
							{{$t('title.deliveryAddressIsDifferent')}}
						</div>
					</LuCartRow>
					<!-- Lieferadresse -->
					<div v-show-slide="$store.getters.deliveryAddressIsDifferent">
						<!-- company -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.company')}}</div>
							<div class="font font--sizeMedium">
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.company"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'company', value: $event.target.value})"
								></BaseInput>
							</div>
						</LuCartRow>
						<!-- name -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.name')}}<span class="requiredIndicator"></span></div>
							<div class="font font--sizeMedium">
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.name"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'name', value: $event.target.value})"
								></BaseInput>
							</div>
						</LuCartRow>
						<!-- street -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.street')}}<span class="requiredIndicator"></span></div>
							<div class="font font--sizeMedium">
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.street"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'street', value: $event.target.value})"
								></BaseInput>
							</div>
						</LuCartRow>
						<!-- zipCode -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.zipCode')}}<span class="requiredIndicator"></span></div>
							<div class="font font--sizeMedium">
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.zipCode"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'zipCode', value: $event.target.value})"
								></BaseInput>
							</div>
						</LuCartRow>
						<!-- city -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.city')}}<span class="requiredIndicator"></span></div>
							<div class="font font--sizeMedium">
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.city"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'city', value: $event.target.value})"
								></BaseInput>
							</div>
						</LuCartRow>
						<!-- country -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.country')}}<span class="requiredIndicator"></span></div>
							<div class="font font--sizeMedium">
								<!--
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.country"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'country', value: $event.target.value})"
								></BaseInput>
								-->
								<BaseSelect
									class="block width width--100"
									:value="$store.getters.deliveryAddress.country"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'country', value: $event.target.value})"
									:defaultOption="$t('message.chooseCountry')"
									:options="$store.getters.countryNames()"
								></BaseSelect>
							</div>
						</LuCartRow>
						<!-- mail -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.mail')}}</div>
							<div class="font font--sizeMedium">
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.mail"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'mail', value: $event.target.value})"
								></BaseInput>
							</div>
						</LuCartRow>
						<!-- phone -->
						<LuCartRow name="billingAndDeliveryAddresses">
							<div>{{$t('title.phone')}}</div>
							<div class="font font--sizeMedium">
								<BaseInput
									type="text"
									:value="$store.getters.deliveryAddress.phone"
									@change="$store.commit('changeAddress', { type: 'deliveryAddress', key: 'phone', value: $event.target.value})"
								></BaseInput>
							</div>
						</LuCartRow>
					</div>
				</LuCartBlock>

				<!-- customer: submit button -->
				<div class="text text--right">
					<LuSpacer :lines="2"></LuSpacer>

					<BaseButton
						class="BaseButton--cartNav"
						tag="a"
						:label="$t('button.back')"
						@click.native="$router.push({ name : 'CheckoutView', params : { slug : 'payment' } })"
					></BaseButton>

					&nbsp;

					<BaseButton
						class="BaseButton--cartNav BaseButton--cta"
						:class="{'BaseButton--disabled' : !this.$store.getters.stepIsValid('customer')}"
						tag="a"
						:label="$t('button.continueCheckout')"
						@click.native="$router.push({ name : 'CheckoutView', params : { slug : 'finishing' } })"
					></BaseButton>

					<LuSpacer :lines="6"></LuSpacer>
				</div>
			</template>

			<!-- Checkout 4/4 - Zusammenfassung + Abschluss -->
			<template v-else-if="$route.params.slug == 'finishing'">
				<LuPageHeader
					:title="'Checkout 4/4'"
					:subTitle="$t('title.finishOrder')"
				></LuPageHeader>

				<!-- finishingCustomer -->
				<LuCartBlock>
					<LuCartRow name="finishingCustomer">
						<div>
							<strong>{{$t('title.billingAddress')}}</strong>
							<div class="font font--sizeMini"><br /></div>
							<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
								<div v-if="$store.getters.billingAddress.company">{{$store.getters.billingAddress.company}}</div>
								<div v-if="$store.getters.billingAddress.name">{{$store.getters.billingAddress.name}}</div>
								<div v-if="$store.getters.billingAddress.street">{{$store.getters.billingAddress.street}}</div>
								<div v-if="$store.getters.billingAddress.zipCode">{{$store.getters.billingAddress.zipCode}} {{$store.getters.billingAddress.city}}</div>
								<div v-if="$store.getters.billingAddress.country">{{$store.getters.billingAddress.country}}</div>
								<div class="font font--sizeMini">
									<br />
								</div>
								<div v-if="$store.getters.billingAddress.mail">{{$store.getters.billingAddress.mail}}</div>
								<div v-if="$store.getters.billingAddress.phone">{{$store.getters.billingAddress.phone}}</div>
								<div v-if="$store.getters.billingAddress.message">{{$store.getters.billingAddress.message}}</div>
								<div class="font font--sizeMini">
									<br />
									<br v-if="!$root.isSmallDevice" />
								</div>
							</div>
						</div>
						<div>
							<div v-if="$root.isSmallDevice" class="border border--top vSpace vSpace--LuCartRow"></div>
							<strong>{{$t('title.deliveryAddress')}}</strong>
							<div class="font font--sizeMini"><br /></div>
							<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }" v-if="$store.getters.deliveryAddressIsDifferent">
								<div v-if="$store.getters.deliveryAddress.company">{{$store.getters.deliveryAddress.company}}</div>
								<div v-if="$store.getters.deliveryAddress.name">{{$store.getters.deliveryAddress.name}}</div>
								<div v-if="$store.getters.deliveryAddress.street">{{$store.getters.deliveryAddress.street}}</div>
								<div v-if="$store.getters.deliveryAddress.zipCode">{{$store.getters.deliveryAddress.zipCode}} {{$store.getters.deliveryAddress.city}}</div>
								<div v-if="$store.getters.deliveryAddress.country">{{$store.getters.deliveryAddress.country}}</div>
							</div>
							<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }" v-else>
								<div v-if="$store.getters.billingAddress.company">{{$store.getters.billingAddress.company}}</div>
								<div v-if="$store.getters.billingAddress.name">{{$store.getters.billingAddress.name}}</div>
								<div v-if="$store.getters.billingAddress.street">{{$store.getters.billingAddress.street}}</div>
								<div v-if="$store.getters.billingAddress.zipCode">{{$store.getters.billingAddress.zipCode}} {{$store.getters.deliveryAddress.city}}</div>
								<div v-if="$store.getters.billingAddress.country">{{$store.getters.billingAddress.country}}</div>
							</div>
							<div class="font font--sizeMini">
								<br />
								<br v-if="!$root.isSmallDevice" />
							</div>
						</div>
						<div>
							<div v-if="$root.isSmallDevice" class="border border--top vSpace vSpace--LuCartRow"></div>
							<strong>{{$t('title.payment')}}</strong><br />
							<div class="font font--sizeMini"><br /></div>
							<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
								<template v-if="$store.getters.paymentOption == 'Invoice'">
									{{$t('title.paymentWithInvoice')}}
								</template>
							</div>
							<div class="font font--sizeMini">
								<br />
								<br v-if="$root.isSmallDevice" />
							</div>
						</div>
					</LuCartRow>
				</LuCartBlock>

				<!-- finishingProducts -->
				<LuCartBlock>
					<!-- header -->
					<LuCartRow name="finishingProducts" v-if="!$store.getters.cartIsEmpty">
						<!-- product -->
						<template v-if="!$root.isSmallDevice">
							<div style="grid-column: span 2">
								<strong class="font font--sizeDefault">
									{{$t('title.products')}}
									<br />
									<br />
								</strong>
							</div>
						</template>
						<template v-else>
							<div>
								<strong class="font font--sizeDefault">
									{{$t('title.products')}}
									<br />
									<br />
								</strong>
							</div>
						</template>

						<!-- priceInclTaxes -->
						<div>
							<template v-if="$store.getters.shippingRegion == 'world'">
								<div class="font font--sizeMini text text--right" v-html="$t('title.priceExclTaxes')"></div>
							</template>
							<template v-else>
								<div class="font font--sizeMini text text--right" v-html="$t('title.priceInclTaxes')"></div>
							</template>
						</div>
						<!-- number -->
						<div>
							<div class="font font--sizeMini text text--center" v-html="$t('title.number')"></div>
						</div>
						<!-- sumInklTaxes -->
						<div>
							<template v-if="$store.getters.shippingRegion == 'world'">
								<div class="font font--sizeMini text text--right" v-html="$t('title.sumExclTaxes2')"></div>
							</template>
							<template v-else>
								<div class="font font--sizeMini text text--right" v-html="$t('title.sumInklTaxes')"></div>
							</template>
						</div>
					</LuCartRow>
					<!-- products -->
					<LuCartRow name="finishingProducts" v-for="(post, index) in $store.getters.itemsInCart" :key="index"
							   class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
						<!-- index -->
						<div v-if="!$root.isSmallDevice">
							{{index+1}}.
						</div>
						<!-- product -->
						<div>
							<router-link class="block" :to="$root.getPathFromUrl(post.link)">
								<span v-html="$root.getTranslatedAcfValue(post.acf, 'author')"></span><br />
								<span v-html="$root.getTranslatedAcfValue(post.acf, 'postTitle')"></span>
							</router-link>
						</div>
						<!-- priceInclTaxes / priceExclTaxes -->
						<div>
							<template v-if="$store.getters.shippingRegion == 'world'">
								<LuPrice
									class="block text text--right"
									:price="getNettoFromBruttoPrice( post.acf.priceEur, post.acf.taxRate )"
								></LuPrice>
							</template>
							<template v-else>
								<LuPrice
									class="block text text--right"
									:price="post.acf.priceEur"
									></LuPrice><br />
							</template>
						</div>
						<!-- anzahl -->
						<div>
							<div class="text text--center">
								{{post.count}}
							</div>
						</div>
						<!-- sumInklTaxes / sumExclTaxes -->
						<div>
							<template v-if="$store.getters.shippingRegion == 'world'">
								<LuPrice
									class="block text text--right"
									:price="post.count * getNettoFromBruttoPrice( post.acf.priceEur, post.acf.taxRate )"
								></LuPrice>
							</template>
							<template v-else>
								<LuPrice
									class="block text text--right"
									:price="post.count * post.acf.priceEur"
								></LuPrice>
							</template>
						</div>
					</LuCartRow>

					<!-- No items in cart -->
					<LuCartRow name="finishingProducts" v-if="$store.getters.cartIsEmpty">
						<div style="grid-column: span 2"
							 class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
							<br />
							{{$t('message.noItemsInCart')}}
							<br />
							<br />
						</div>
					</LuCartRow>
				</LuCartBlock>

				<!-- finishingShipping -->
				<LuCartBlock>
					<!-- header -->
					<LuCartRow name="finishingShipping">
						<div style="grid-column: span 5">
							<strong class="font font--sizeDefault">
								{{$t('title.shipping')}}
								<br />
								<br />
							</strong>
						</div>
					</LuCartRow>
					<!-- shipping -->
					<LuCartRow name="finishingShipping"
							   class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
						<!-- index -->
						<div v-if="!$root.isSmallDevice">
							<div class="font font--sizeMedium">
								{{$store.getters.itemsInCart.length+1}}.
							</div>
						</div>
						<!-- product (shipping) -->
						<div>
							<div>
								<template v-if="$store.getters.shippingRegion == 'germany'">
									{{$t('title.shippingToGermany')}}
									<br />
								</template>
								<template v-if="$store.getters.shippingRegion == 'eu'">
									{{$t('title.shippingToEU')}}
									<br />
								</template>
								<template v-if="$store.getters.shippingRegion == 'world'">
									{{$t('title.shippingToWorldwide')}}: {{$store.getters.shippingCountry}}
									<br />
								</template>
								<br />
							</div>
						</div>
						<!-- priceInclTaxes -->
						<div>
							<LuPrice
								class="block text text--right"
								:price="$store.getters.shippingPrice"
							></LuPrice>
						</div>
						<!-- number -->
						<div>
							<div class="text text--center">1</div>
						</div>
						<!-- sumInklTaxes -->
						<div>
							<div>
								<LuPrice
									class="block text text--right"
									:price="$store.getters.shippingPrice"
								></LuPrice>
							</div>
						</div>
					</LuCartRow>
				</LuCartBlock>

				<!-- finishingSum -->
				<LuCartBlock v-if="!$store.getters.cartIsEmpty">
					<LuCartRow name="finishingSum" v-if="!$store.getters.cartIsEmpty"
							   class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
						<div v-if="!$root.isSmallDevice">
							<br />
							<br />
						</div>
						<!-- prices label -->
						<div>
							<div class="text text--right">
								<template v-if="$store.getters.shippingRegion == 'world'">
									<span class="font font--sizeSmall" v-html="$t('title.taxFreeDelivery')"></span><br />
									<span class="font font--sizeSmall font--bold" v-html="$t('title.invoiceAmount')"></span><br />
								</template>
								<template v-else>
									<span class="font font--sizeSmall" v-html="$t('title.includedTaxesSum')"></span><br />
									<span class="font font--sizeSmall" v-html="$t('title.sumExclTaxes')"></span><br />
									<span class="font font--sizeSmall font--sizeSmall font--bold" v-html="$t('title.invoiceAmountInclVat')"></span><br />
								</template>
							</div>
						</div>
						<!-- spacer -->
						<div v-if="!$root.isSmallDevice"></div>
						<!-- prices -->
						<div>
							<div class="text text--right">
								<template v-if="$store.getters.shippingRegion == 'world'">
									<br />
									<LuPrice class="font font--bold" :price="$store.getters.nettoSumOfAllProducts + $store.getters.shippingPrice"></LuPrice><br />
								</template>
								<template v-else>
									<LuPrice :price="$store.getters.sumOfAllProductsTaxes + $store.getters.sumOfShippingTaxes"></LuPrice><br />
									<LuPrice :price="$store.getters.nettoSumOfAllProducts + $store.getters.nettoShippingPrice"></LuPrice><br />
									<LuPrice class="font font--bold" :price="$store.getters.bruttoSumOfAllProducts + $store.getters.shippingPrice"></LuPrice><br />
								</template>
							</div>
						</div>
						<div v-if="!$root.isSmallDevice"></div>
					</LuCartRow>
				</LuCartBlock>

				<!-- finishingAccept: gtb, pp, newsletter -->
				<LuCartBlock>
					<LuCartRow name="finishingAccept">
						<div>
							<BaseInput
								type="checkbox"
								@change="$store.commit('changeAcceptedGTB', $event.target.checked)"
								:checked="$store.getters.acceptedGTB"
							></BaseInput>
						</div>
						<div>
							<div class="font font--sizeSmall">
								<span v-html="$t('message.gtbAcceptedInfo')"></span>
							</div>
						</div>
						<div></div>
					</LuCartRow>
					<LuCartRow name="finishingAccept">
						<div>
							<BaseInput
								type="checkbox"
								@change="$store.commit('changeAcceptedPP', $event.target.checked)"
								:checked="$store.getters.acceptedPP"
							></BaseInput>
						</div>
						<div>
							<div class="font font--sizeSmall">
								<span v-html="$t('message.ppAcceptedInfo')"></span>
							</div>
						</div>
						<div></div>
					</LuCartRow>
					<LuCartRow name="finishingAccept">
						<div>
							<BaseInput
								type="checkbox"
								@change="$store.commit('changeAcceptedNewsletter', $event.target.checked)"
								:checked="$store.getters.acceptedNewsletter"
							></BaseInput>
						</div>
						<div>
							<div class="font font--sizeSmall">
								<span v-html="$t('message.newsletterAcceptedInfo')"></span>
							</div>
						</div>
						<div></div>
					</LuCartRow>
				</LuCartBlock>

				<!-- finishing: submit button -->
				<div class="text text--right">
					<LuSpacer :lines="2"></LuSpacer>

					<BaseButton
						class="BaseButton--cartNav"
						tag="a"
						:label="$t('button.back')"
						@click.native="$router.push({ name : 'CheckoutView', params : { slug : 'customer' } })"
					></BaseButton>

					&nbsp;

					<BaseButton
						class="BaseButton--cartNav BaseButton--cta"
						:class="{'BaseButton--disabled' : !this.$store.getters.stepIsValid('finishing'), 'BaseButton--loading' : showButtonSpinner}"
						tag="a"
						:label="$t('button.placeOrder')"
						@click.native="submitOrder()"
					></BaseButton>

					<LuSpacer :lines="6"></LuSpacer>
				</div>
			</template>

			<!-- Thank You  -->
			<template v-else-if="$route.params.slug == 'thankYou'">
				<LuPageHeader
					:title="$t('title.orderSubmitted')"
				></LuPageHeader>

				<LuCartBlock :borderBottom="false">
					<LuCartRow name="thankYou">
						<!-- text -->
						<div class="font" :class="{ 'font--sizeMedium' : !$root.isSmallDevice, 'font--sizeDefault' : $root.isSmallDevice }">
							<span v-html="$t('message.orderSubmitted')"></span><br />
							<br />
							<br />
							<br />
							<br />
						</div>
						<!-- spacer -->
						<div v-if="!$root.isSmallDevice"></div>
					</LuCartRow>
				</LuCartBlock>
			</template>

			<!--
			<pre name="stepIsValid('cart')">{{$store.getters.stepIsValid('cart')}}</pre>
			<pre name="stepIsValid('shipping')">{{$store.getters.stepIsValid('shipping')}}</pre>
			<pre name="stepIsValid('payment')">{{$store.getters.stepIsValid('payment')}}</pre>
			<pre name="stepIsValid('customer')">{{$store.getters.stepIsValid('customer')}}</pre>
			<pre name="stepIsValid('finishing')">{{$store.getters.stepIsValid('finishing')}}</pre>

			<pre name="$store.state.mhCart.customer">{{$store.state.mhCart.customer}}</pre>
			-->

		</div>

		<!-- expander to keep the shoppingInfos at window bottom -->
		<div class="flex flex--grow"></div>

		<!-- shoppingInfos in 3 cols -->
		<div class="hSpace hSpace--page maxWidth maxWidth--page">
			<div class="grid grid--LubokCart-shoppingInfos border border--top border--strong">
				<LuText
					:lineAbove="false"
					:textOnBlack="false">
					<strong v-html="$root.getTranslatedAcfValue( acfOptions, 'cartFooter-firstCol-title')"></strong><br />
					<div class="font font--sizeMini"><br /></div>
					<div    v-html="$root.getTranslatedAcfValue( acfOptions, 'cartFooter-firstCol-text')"></div>
				</LuText>

				<div v-if="$root.isSmallDevice" class="border border--top vSpace vSpace--LuCartRow vSpace--noBottom"></div>

				<LuText
					:lineAbove="false"
					:textOnBlack="false">
					<strong v-html="$root.getTranslatedAcfValue( acfOptions, 'cartFooter-secondCol-title')"></strong><br />
					<div class="font font--sizeMini"><br /></div>
					<div    v-html="$root.getTranslatedAcfValue( acfOptions, 'cartFooter-secondCol-text')"></div>
				</LuText>

				<div v-if="$root.isSmallDevice" class="border border--top vSpace vSpace--LuCartRow vSpace--noBottom"></div>

				<LuText
					:lineAbove="false"
					:textOnBlack="false">
					<strong v-html="$root.getTranslatedAcfValue( acfOptions, 'cartFooter-thirdCol-title')"></strong><br />
					<div class="font font--sizeMini"><br /></div>
					<div    v-html="$root.getTranslatedAcfValue( acfOptions, 'cartFooter-thirdCol-text')"></div>
				</LuText>
			</div>
		</div>

	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from './View.mixin.js'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'

	import BaseButton from '@/components/Base/BaseButton.vue'
	import BaseInput from '@/components/Base/BaseInput.vue'
	import BaseSelect from '@/components/Base/BaseSelect.vue'
	import BaseTextarea from '@/components/Base/BaseTextarea.vue'

	import LuMobileHeader from '@/components/LuMobileHeader.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuPrice from '@/components/LuPrice.vue'
	import LuSpacer from '@/components/LuSpacer.vue'
	import LuCartBlock from '@/components/LuCartBlock.vue'
	import LuCartRow from '@/components/LuCartRow.vue'
	import LuText from '@/components/LuText.vue'

	export default {
		name: 'LubokCart',
		components: {
			MhDevGrid,
			MhSmartHeader,
			BaseButton,
			BaseInput,
			BaseSelect,
			BaseTextarea,
			LuMobileHeader,
			LuPageHeader,
			LuPrice,
			LuSpacer,
			LuCartBlock,
			LuCartRow,
			LuText,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {
				shippingPriceToEU      : null,
				shippingPriceToGermany : null,
				acfOptions             : {},
				showButtonSpinner      : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
		},
		methods: {
			getNettoFromBruttoPrice( bruttoPrice, taxRate ){
				const divider    = 1 + ( taxRate / 100 )
				const nettoPrice = this._.round( bruttoPrice / divider, 2 )
				const taxPrice   = this._.round( bruttoPrice - nettoPrice, 2 )

				return nettoPrice
			},
			submitOrder(){
				this.showButtonSpinner = true

				const tsBeforeSend         = Date.now()
				const responseMinDuration  = 1000
				const emptyCartAfterSubmit = true // for debugging
				const requestData 		   = {
					cart : this.$store.getters.cart,
					shipping : {
						region : this.$store.getters.shippingRegion,
						country : this.$store.getters.shippingCountry,
					},
					customer : {
						billingAddress     : this.$store.getters.billingAddress,
						deliveryAddress    : this.$store.getters.deliveryAddressIsDifferent ? this.$store.getters.deliveryAddress : this.$store.getters.billingAddress,
						acceptedNewsletter : this.$store.getters.acceptedNewsletter,
					},
					payment : {
						option : this.$store.getters.paymentOption,
					},
					language : this.$root.currentLang,
					timestamp : Date.now(), // prevent caching, just for debugging
				}

				// submit order data to server
				this.restHandler__fetch({
					action : 'POST',
					route  : '/wp-json/mh/v1/lubok__handleOrder',
					data   : requestData,
					callback : (response) => {
						const result          = response.data.result
						const tsAfterResponse = Date.now()
						const submitDuration  = tsAfterResponse - tsBeforeSend
						let debugLog          = false

						// response should at least take responseMinDuration for better ux
						if( submitDuration < responseMinDuration ){
							setTimeout( () => {
								if( emptyCartAfterSubmit ) this.$store.commit('emptyCart')
								this.showButtonSpinner = false
								this.$router.push({ name : 'CheckoutView', params : { slug : 'thankYou' } })
							}, responseMinDuration-submitDuration)
						}
						else{
							if( emptyCartAfterSubmit ) this.$store.commit('emptyCart')
							this.showButtonSpinner = false
							this.$router.push({ name : 'CheckoutView', params : { slug : 'thankYou' } })
						}
					},
				})

			},
		},
		created() {},
		mounted() {
			// send loading to MhView to show the spinner
			EventBus.$emit('stateChanged', 'loading')

			// let MhCart.vue the work do
			EventBus.$emit('mhCart__fetchProducts', () => {
				this.viewIsReady = true // this comes from View.mixin.js

				if( !this.$route.params.slug ) this.title = this.$t('title.cart')
				if( this.$route.params.slug == 'shipping' ) this.title = this.$t('title.shipping')
				if( this.$route.params.slug == 'payment' ) this.title = this.$t('title.payment')
				if( this.$route.params.slug == 'customer' ) this.title = this.$t('title.billingAndDeliveryAddresses')
				if( this.$route.params.slug == 'finishing' ) this.title = this.$t('title.finishOrder')
				if( this.$route.params.slug == 'thankYou' ) this.title = this.$t('title.orderSubmitted')

				// send loaded to MhView to hide the spinner again
				EventBus.$emit('stateChanged', 'loaded')
			})

			// on load:
			// set start shipping region + country + paymentOption
			if( !this.$store.state.mhCart.customer.shippingRegion ){
				//this.$store.commit('changeShippingRegion', 'germany')
			}
			if( !this.$store.state.mhCart.customer.paymentOption ){
				this.$store.commit('changePaymentOption', 'Invoice')
			}

			// fetch acfOptions
			// -	to get general shop data (shipping countries and prices)
			// 		after: commit 'addShippingRegion' to store
			// -	get info texts
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					const result                 = response.data.result
					const shippingPriceToEU      = result.shippingPriceToEU ? parseFloat( result.shippingPriceToEU ) : 0
					const shippingPriceToGermany = result.shippingPriceToGermany ? parseFloat( result.shippingPriceToGermany ) : 0
					const euCountries            = result.euCountries.split('\n')
					const worldCountries         = result.worldCountries.split('\n')
					let   debugLog               = false

					this.shippingPriceToEU = shippingPriceToEU
					this.shippingPriceToGermany = shippingPriceToGermany
					this.acfOptions = result

					//console.log('euCountries:', euCountries);
					//console.log('worldCountries:', worldCountries);

					// 1. world countries > addShippingCountry
					if( debugLog ) console.groupCollapsed('world countries > addShippingCountry')
					this._.forEach( worldCountries, country => {
						const parts = country.split('=')
						const name = this._.trim( parts[0] )
						const price = parts[1] ? parseFloat( parts[1].replace(',', '.') ) : 0
						const countryObj = {
							name : name,
							shippingPrice : price,
							region : 'world',
						}

						if( name ){
							//console.log(countryObj);
							this.$store.commit('addShippingCountry', countryObj)
						}
					})
					if( debugLog ) console.groupEnd()

					// 2. eu countries > addShippingCountry
					if( debugLog ) console.groupCollapsed('eu countries > addShippingCountry')
					this._.forEach( euCountries, country => {
						const name = this._.trim( country )
						const price = shippingPriceToEU
						const countryObj = {
							name : name,
							shippingPrice : price,
							region : 'eu',
						}

						if( name ){
							if( debugLog ) console.log(countryObj)
							this.$store.commit('addShippingCountry', countryObj)
						}
					})
					if( debugLog ) console.groupEnd()

					// 3. add all shipping regions
					this.$store.commit('addShippingRegion', { name : 'germany', shippingPrice : shippingPriceToGermany })
					this.$store.commit('addShippingRegion', { name : 'eu', shippingPrice : shippingPriceToEU })
					this.$store.commit('addShippingRegion', { name : 'world', shippingPrice : false })
				},
			})
		},
	}
</script>

<style lang="less">
	.LubokCartView {
		min-height: 100vh;

		.requiredIndicator {
			display: inline-block;
			padding-left: 0.2em;
			opacity: 0.7;

			&::before { content: "*"; }
		}
	}

	.BaseInput {}
	.BaseInput--number {
		display: block;
		display: flex;
		justify-content: center;

		.BaseInput__input {
			width: 2rem;
			text-align: center;
		}
		input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
		    -webkit-appearance: none;
		    margin: 0;
		}

		input[type="number"] {
		    -moz-appearance: textfield;
		}
	}
	.BaseTextarea,
	.BaseInput--text {
		display: block;

		.BaseTextarea__input,
		.BaseInput__input {
			width: 100%;
			//text-align: center;
		}
	}
</style>
