<!--

	Die Componente dient als transparent Wrapper für Textarea Elemente

	Props:


	Markup:
		<BaseTextarea
			@change="myFunction( $event.target.value )"
			:value="someNumber"
		></BaseTextarea>

		<BaseTextarea
			label="Vorname"
			@change="myFunction( $event.target.value )"
			:value="someString"
		></BaseTextarea>

	Changelog
		2019-06-13	init

-->

<template>
	<label class="BaseTextarea" :class="elmClasses">
		<span class="BaseTextarea__label">{{ label }}</span>
		<textarea
			ref="input"
			class="BaseTextarea__input"
		  	:value="value"
		  	v-bind="$attrs"
		  	v-on="listeners"
		  	@input="input"
		/>
	</label>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'BaseInput',
		components: {},
		mixins: [],
		props: ['value', 'label'],
		data() {
			return {
				type : null
			}
		},
		watch: {},
		computed: {
			elmClasses(){
				 let classes = []

				 classes.push( 'BaseInput--' + this.type )

				 return classes
			},
			listeners(){
				const { input, ...listeners } = this.$listeners;
				return listeners;
			},
		},
		methods: {
			input( event ) {
				this.$emit('input', event.target.value);
			},
		},
		created() {},
		mounted() {
			this.type = this.$refs.input.type
		},
	}
</script>

<style lang="less">
	.BaseTextarea {
		position: relative;

		.BaseTextarea__input {
			background-color: fade(black, 5);
			border: none;
			font: inherit;
			max-width: 100%;
			height: 1.6em;
			border-radius: 2px;
			border: 1px solid fade(black, 10);
			resize: vertical;
		}
	}
	.BaseTextarea {
		.BaseTextarea__input:focus {
			background-color: fade(black, 10);
			outline: none;
		}
	}
	.BaseTextarea {
		.BaseTextarea__input {
			padding-top: 0.1em;
			padding-left: 0.3em;
			height: 5em;
		}
	}
</style>
