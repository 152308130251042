<!--

	Die Componente dient als transparent Wrapper für Select Elemente

	Props:
		value		String	Wird als value des Selects verwendet
		options		Array	Werden als <option> verwendet
		label 		String	Wird vor dem Input angezeigt

	Markup:
		<BaseSelect
			label="BaseSelect"
			@change="valueChanged( $event.target.value )"
			:value="'Heiko'"
			:defaultOption="'Choose ...'"
			:options="['Mario', 'Heiko', 'Silvio']"
		></BaseSelect>

	Changelog
		2019-06-13	init

-->

<template>
	<label class="BaseSelect">
		<span class="BaseInput__label">{{ label }}</span><select
			class="BaseSelect__select"
			:value="value"
			v-bind="$attrs"
			v-on="listeners"
			@input="input">
			<option
				v-if="defaultOption"
				value=""
				v-html="defaultOption"
				></option>
			<option
				v-for="(option, index) in options"
				:key="index"
				:value="option"
				v-html="option"
			></option>
		</select>
		<span class="BaseSelect__arrow">▼</span>
	</label>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'BaseSelect',
		components: {},
		mixins: [],
		props: ['value', 'label', 'defaultOption', 'options'],
		data() {
			return {}
		},
		watch: {},
		computed: {
			showDefaultOption(){
				return true
			},
			listeners() {
				const { input, ...listeners } = this.$listeners;
				return listeners;
			},
		},
		methods: {
			input(event) {
				this.$emit('input', event.target.value);
			},
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	.BaseSelect {
		position: relative;

		background-color: fade( black, 5 );
		width: 100%;
		padding-left: 0.3em;
		height: 1.6em;
		border-radius: 2px;
		border: 1px solid fade(black, 10);
		cursor: pointer;

		&__select {
			max-width: 100%;
			width: 100%;
			height: 100%;
			cursor: pointer;

			border: none;
			outline: none;
			background-color: transparent;
			-webkit-appearance: none;
    		-moz-appearance: none;
    		appearance: none;
			font: inherit;
			padding: 0;
			margin: 0;
		}
		&__label {
			display: none;
		}
		&__arrow {
			//background-color: fade(red, 60);

			position: absolute;
			top: 0; right: 0;
			bottom: 0;
			width: 2.5em;
			font-size: 0.65em;
			line-height: 1em;
			opacity: 0.6;

			display: flex;
			justify-content: center;
			align-items: center;
			pointer-events: none;
		}

		&:hover &__arrow {
			opacity: 1;
		}
	}
</style>
