<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LuCartRow></LuCartRow>

	2019-06-08	init

-->

<template>
	<div class="LuCartRow vSpace--LuCartRow" :class="elmClasses">
		<slot></slot>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'LuCartRow',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//number: [Number, Boolean],
			name: [String],
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('LuCartBlock__row')
				classes.push('border')
				classes.push('border--bottom')
				classes.push('grid')
				//classes.push('grid--setAreas')
				if(this.name) classes.push('grid--LuCartRow-' + this.name)

				return classes
			},
		},
		methods: {},
		updated: function () {
			//console.log('updated:', this.$slots.default)

			//this.addChildClasses()
		},
		created() {},
		mounted() {
			//console.log('mounted:', this.$slots.default)

			//this.addChildClasses()
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";

	[showborders4] .LuCartRow {
		& > * {
			background-color: fade( blue, 20 );
		}
	}

	.LuCartRow {
		position: relative;
		// wird ja schon über .grid in atoms.less gesetzt
		//grid-column-gap: @colGap;

		&:last-child {
			border: none;
		}

		a.link {
			color: inherit;
			border-bottom: 1px dotted;
		}
		a.link:hover {
			border-bottom-style: solid;
		}
		a.block {
			color: inherit;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
